import { TableCell, Typography } from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import { AmountText, primaryColor } from '@orus.eu/pharaoh'

export type TableCellAmountProps = {
  amount: Amount
}

export function TableCellAmount({ amount }: TableCellAmountProps): JSX.Element {
  return (
    <TableCell>
      <Typography variant="subtitle2" color={primaryColor}>
        <AmountText amount={amount} displayDecimals={true} />
      </Typography>
    </TableCell>
  )
}
