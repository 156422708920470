import type { Invoice } from '@orus.eu/backend/src/views/invoicing/invoice-view'
import { PARIS, getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import { assert } from './errors'
import { formatDate } from './format'

export function getInvoicePeriodLabel(invoice: Invoice): string {
  assert(invoice.items.length === 1, 'Invoice should have exactly one item')
  const invoiceItem = invoice.items[0]
  return getInvoicePeriodLabelFromTimestamps(invoiceItem)
}

export type PeriodLabelParams = {
  periodStartTimestamp: number
  periodEndTimestamp: number
}

export function getInvoicePeriodLabelFromTimestamps(params: PeriodLabelParams): string {
  const { periodStartTimestamp, periodEndTimestamp } = params
  const start = formatDate(periodStartTimestamp)
  const end = formatDate(
    getZonedDateTimeFromMillis(periodEndTimestamp, PARIS)
      .minus({ day: 1 }) // we want the last day to be included
      .toMillis(),
  )
  return `${start} au ${end}`
}
