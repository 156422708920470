import { css } from '@emotion/react'
import { TableCell } from '@mui/material'
import type { PaymentProviderName } from '@orus.eu/backend/src/events/stripe-object-update'
import { ButtonLink, spacing, type ButtonVariant } from '@orus.eu/pharaoh'
import { getGocardlessPaymentIntentUrl, getStripePaymentIntentUrl } from '../../../lib/payment-provider-util'

export type TableCellPaymentIntentPaymentProviderActionsProps = {
  paymentIntentId: string
  provider: PaymentProviderName | undefined
  variant?: ButtonVariant
  showCancelAction?: boolean
}

export function TableCellPaymentIntentPaymentProviderActions(
  props: TableCellPaymentIntentPaymentProviderActionsProps,
): JSX.Element {
  const { paymentIntentId, provider, variant } = props

  return (
    <TableCell>
      <div
        css={css`
          display: flex;
          gap: ${spacing[40]};
        `}
      >
        <ButtonLink
          size="small"
          variant={variant}
          icon="arrow-up-right-from-square-regular"
          avatarPosition="right"
          to={
            provider === 'gocardless'
              ? getGocardlessPaymentIntentUrl(paymentIntentId)
              : getStripePaymentIntentUrl(paymentIntentId)
          }
          target="_blank"
        >
          {provider === 'gocardless' ? 'GoCardless' : 'Stripe'}
        </ButtonLink>
      </div>
    </TableCell>
  )
}
