import type { UpdatedPaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import { colors } from '@orus.eu/pharaoh'
import { RawPaymentStatusTag } from './raw-payment-status-tag'

export type UpdatedPaymentStatusTagProps = {
  paymentStatus: UpdatedPaymentStatus
}

/**
 * A tag that displays the status of a undated payment
 * @param paymentStatus
 * @constructor
 */
export function UpdatedPaymentStatusTag({ paymentStatus }: UpdatedPaymentStatusTagProps): JSX.Element {
  switch (paymentStatus) {
    case 'disputed':
      return <RawPaymentStatusTag text="Paiement contesté" backgroundColor={colors.orange[200]} icon="bolt-solid" />
    case 'failed':
      return (
        <RawPaymentStatusTag text="Impayée" backgroundColor={colors.orange[200]} icon="triangle-exclamation-regular" />
      )
    case 'refunded':
      return (
        <RawPaymentStatusTag
          text="Remboursée"
          backgroundColor={colors.yellow[200]}
          icon="triangle-exclamation-regular"
        />
      )
    case 'cancelled':
      return (
        <RawPaymentStatusTag
          text="Paiement annulé"
          backgroundColor={colors.yellow[200]}
          icon="triangle-exclamation-regular"
        />
      )
    case 'paid':
      return <RawPaymentStatusTag text="Payée" backgroundColor={colors.green[200]} icon="circle-check-regular" />
    case 'pending':
      return (
        <RawPaymentStatusTag text="Paiement en cours" backgroundColor={colors.green[200]} icon="hourglass-regular" />
      )
  }
}
