import { css } from '@emotion/react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type SxProps,
} from '@mui/material'
import type { Amount } from '@orus.eu/amount'
import type { PaymentStatus } from '@orus.eu/backend/src/events/payment-update'
import type { PaymentProviderName } from '@orus.eu/backend/src/events/stripe-object-update'
import type { InvoicingItem } from '@orus.eu/backend/src/views/invoicing/invoice-payment-status-view'
import { Button, colors, spacing, useAsyncCallback } from '@orus.eu/pharaoh'
import { useState } from 'react'
import { trpc } from '../../../client'
import { formatDateTime } from '../../../lib/format'
import { getInvoicePeriodLabel, getInvoicePeriodLabelFromTimestamps } from '../../../lib/invoice-util'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { usePermissions } from '../../../lib/use-permissions'
import { TableCellAmount } from '../../molecules/table-elements/table-cell-amount'
import { TableCellHeader } from '../../molecules/table-elements/table-cell-header'
import { TableCellInvoicingItemStatusTag } from '../../molecules/table-elements/table-cell-invoicing-item-status-tag'
import { TableCellPaymentIntentPaymentProviderActions } from '../../molecules/table-elements/table-cell-payment-intent-payment-provider-actions'
import { TableCellText } from '../../molecules/table-elements/table-cell-text'
import { CancelInvoiceButton } from './cancel-invoice-button'
import { DeleteInvoiceButton } from './delete-invoice-button'
import { InvoiceDocumentsDownloadButtons } from './invoice-documents-download-buttons'
import { DraftInvoiceStatusTag } from './invoicing-item-status-tag'
import { PayInvoiceButton } from './pay-invoice-button'
import { ValidateInvoiceButton } from './validate-invoice-button'

type InvoiceListProps = {
  invoicingItems: InvoicingItem[]
  /**
   * Allow displaying a draft preview row. Leave undefined to have no preview row.
   * Set to null to have a blank preview row.
   * Set to a non-null value to have a filled draft preview row.
   */
  draftPreviewRow?: DraftPreviewRowProps | null
  sx?: SxProps
}

export function InvoiceList(props: InvoiceListProps): JSX.Element {
  const { invoicingItems, draftPreviewRow, sx } = props
  const orderedInvoicingItems = [...invoicingItems]
  orderedInvoicingItems.sort((a, b) => {
    return b.invoice.dueTimestamp - a.invoice.dueTimestamp
  })

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHeader>Due le</TableCellHeader>
            <TableCellHeader>Période</TableCellHeader>
            <TableCellHeader>Statut</TableCellHeader>
            <TableCellHeader>Montant</TableCellHeader>
            <TableCellHeader>Document</TableCellHeader>
            <TableCellHeader>Action</TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {draftPreviewRow === null ? <BlankPreviewRow /> : <></>}
          {draftPreviewRow ? <DraftPreviewRow {...draftPreviewRow} /> : <></>}
          {orderedInvoicingItems.map((invoicingItem) => (
            <InvoiceListRow key={invoicingItem.invoice.invoiceId} invoicingItem={invoicingItem} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type InvoiceListRowProps = {
  invoicingItem: InvoicingItem
}

function InvoiceListRow({ invoicingItem }: InvoiceListRowProps): JSX.Element {
  const { invoice, type } = invoicingItem
  const invoiceId = invoice.invoiceId
  const [synchronizing, setSynchronizing] = useState(false)
  const { permissions } = usePermissions()

  const synchronizeInvoicePaymentStatus = useAsyncCallback(async () => {
    setSynchronizing(true)
    await trpc.invoices.synchronizeInvoicePaymentAttempts.mutate(invoiceId)
    document.location.reload()
  }, [invoiceId])

  let lastTentativePaymentIntentId: string | undefined
  let lastTentativePaymentProvider: PaymentProviderName | undefined
  if (type === 'emitted') {
    lastTentativePaymentIntentId = invoicingItem.lastPaymentTentativeIntentId
    lastTentativePaymentProvider = invoicingItem.lastPaymentTentativeProvider
  }

  return (
    <TableRow key={invoiceId}>
      <TableCellText text={formatDateTime(invoice.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabel(invoice)} />
      <TableCellInvoicingItemStatusTag invoicingItem={invoicingItem} />
      <TableCellAmount amount={invoice.totalPremium} />
      <TableCell>
        <InvoiceDocumentsDownloadButtons invoicingItem={invoicingItem} />
      </TableCell>
      <TableCell>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${spacing[50]};
          `}
        >
          <InvoiceActionValidate invoicingItem={invoicingItem} />
          <InvoiceActionDelete invoicingItem={invoicingItem} />
          <InvoiceActionPay invoicingItem={invoicingItem} />
          <InvoiceActionCancel invoicingItem={invoicingItem} />
          {permissions.includes('stripe.read') ? (
            <Button
              disabled={synchronizing}
              variant="secondary"
              size="small"
              icon="arrows-rotate-regular"
              title="Synchroniser le statut de paiement"
              onClick={synchronizeInvoicePaymentStatus}
            ></Button>
          ) : null}
          {lastTentativePaymentIntentId && permissions.includes('stripe.read') && (
            <TableCellPaymentIntentPaymentProviderActions
              variant="secondary"
              provider={lastTentativePaymentProvider}
              paymentIntentId={lastTentativePaymentIntentId}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export type DraftPreviewRowProps = {
  dueTimestamp: number
  periodStartTimestamp: number
  periodEndTimestamp: number
  totalPremium: Amount
}

function DraftPreviewRow(props: DraftPreviewRowProps): JSX.Element {
  return (
    <TableRow
      sx={{
        backgroundColor: colors.yellow[200],
        outline: `2px solid ${colors.red[500]}`,
      }}
    >
      <TableCellText text={formatDateTime(props.dueTimestamp)} />
      <TableCellText text={getInvoicePeriodLabelFromTimestamps(props)} />
      <TableCell>
        <DraftInvoiceStatusTag />
      </TableCell>
      <TableCellAmount amount={props.totalPremium} />
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

function BlankPreviewRow(): JSX.Element {
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography
          component="p"
          sx={{
            color: colors.gray[200],
            textAlign: 'center',
            padding: '2px', // have same height as rows with tags
          }}
        >
          Un aperçu sera généré une fois tous les champs remplis !
        </Typography>
      </TableCell>
    </TableRow>
  )
}

type InvoiceActionProps = {
  invoicingItem: InvoicingItem
}

function InvoiceActionPay({ invoicingItem }: InvoiceActionProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)

  if (
    invoicingItem.type === 'emitted' &&
    invoicingItem.invoice.status === 'valid' &&
    payableInvoiceStatus[invoicingItem.paymentStatus] &&
    invoicingItem.invoice.dueTimestamp <= currentTimestamp
  ) {
    return (
      <PayInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} confirmText="Tentative de paiement lancée">
        Payer
      </PayInvoiceButton>
    )
  }

  return <></>
}

function InvoiceActionValidate({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return <ValidateInvoiceButton invoiceId={invoicingItem.invoice.invoiceId}>Valider</ValidateInvoiceButton>
  }

  return <></>
}

function InvoiceActionDelete({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (invoicingItem.type === 'draft') {
    return <DeleteInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} />
  }

  return <></>
}

function InvoiceActionCancel({ invoicingItem }: InvoiceActionProps): JSX.Element {
  if (
    invoicingItem.type === 'emitted' &&
    ((invoicingItem.invoice.status === 'valid' && invoicingItem.paymentStatus === 'new') ||
      invoicingItem.paymentStatus === 'failed' ||
      invoicingItem.paymentStatus === 'cancelled' ||
      invoicingItem.paymentStatus === 'refunded')
  ) {
    return <CancelInvoiceButton invoiceId={invoicingItem.invoice.invoiceId} />
  }

  return <></>
}

const payableInvoiceStatus: { [key in PaymentStatus]: boolean } = {
  new: true,
  failed: true,
  cancelled: true,
  refunded: true,
  disputed: false,
  paid: false,
  pending: false,
}
