import { Button, useAsyncCallback } from '@orus.eu/pharaoh'
import { useState, type ReactText } from 'react'
import { trpc } from '../../../client'

type PayInvoiceButtonProps = {
  invoiceId: string
  children?: ReactText
  confirmText: string
}

/**
 * This button attempts the payment of an invoice when clicked. After it displays the result and cause the
 * page to reload, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @param children
 * @param confirmText
 * @constructor
 */
export function PayInvoiceButton({ invoiceId, children, confirmText }: PayInvoiceButtonProps): JSX.Element {
  const [state, setState] = useState<'idle' | 'working'>('idle')

  const attemptPayment = useAsyncCallback(async () => {
    setState('working')
    const attemptResult = await trpc.invoices.attemptInvoicePayment.mutate(invoiceId)

    alert(
      attemptResult.type === 'success'
        ? confirmText
        : `Échec : ${'reason' in attemptResult.problem ? attemptResult.problem.reason : attemptResult.problem}`,
    )

    document.location.reload()
  }, [confirmText, invoiceId])

  return (
    <Button
      size="small"
      variant="primary"
      disabled={state !== 'idle'}
      onClick={attemptPayment}
      isLoading={state === 'working'}
    >
      {children}
    </Button>
  )
}
