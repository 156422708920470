import { colors, type CompoundIconName } from '@orus.eu/pharaoh'
import { Tag } from '../../molecules/tag'

export type RawPaymentStatusTagProps = {
  text: string
  backgroundColor: string
  icon: CompoundIconName
}

/**
 * A dumb component that implement the common parts of the UI of all the payment status tags
 * @param text
 * @param backgroundColor
 * @param icon
 * @constructor
 */
export function RawPaymentStatusTag({ text, backgroundColor, icon }: RawPaymentStatusTagProps): JSX.Element {
  return <Tag variant="colors" text={text} color={colors.gray[900]} backgroundColor={backgroundColor} icon={icon} />
}
