import type {
  InvoicePaymentStatus,
  InvoicingItem,
} from '@orus.eu/backend/src/views/invoicing/invoice-payment-status-view'
import type { EmittedInvoice } from '@orus.eu/backend/src/views/invoicing/invoice-view'
import { PARIS, getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import { colors } from '@orus.eu/pharaoh'
import type { FC } from 'react'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { RawPaymentStatusTag } from './raw-payment-status-tag'
import { UpdatedPaymentStatusTag } from './updated-payment-status-tag'

export type InvoicingItemStatusTagProps = {
  invoicingItem: InvoicingItem
}

/**
 * A tag that displays the payment status of an invoice
 * @param invoicingItem
 * @constructor
 */
export function InvoicingItemStatusTag({ invoicingItem }: InvoicingItemStatusTagProps): JSX.Element {
  switch (invoicingItem.type) {
    case 'draft':
      return <DraftInvoiceStatusTag />
    case 'deleted':
      return <></>
    case 'emitted': {
      return <EmittedInvoicePaymentStatusTag invoicePaymentStatus={invoicingItem} />
    }
  }
}

type EmittedInvoicePaymentStatusTagProps = {
  invoicePaymentStatus: InvoicePaymentStatus
}

export function EmittedInvoicePaymentStatusTag({
  invoicePaymentStatus,
}: EmittedInvoicePaymentStatusTagProps): JSX.Element {
  switch (invoicePaymentStatus.invoice.status) {
    case 'cancelled':
      return <CancelledStatusTag invoicePaymentStatus={invoicePaymentStatus} />
    case 'valid':
      return <ValidStatusTag invoicePaymentStatus={invoicePaymentStatus} />
  }
}

type UnpaidInvoicePaymentStatusTagProps = {
  invoice: EmittedInvoice
  status: 'failed' | 'refunded' | 'cancelled' | 'disputed'
}

const CancelledStatusTag: FC<{ invoicePaymentStatus: InvoicePaymentStatus }> = ({ invoicePaymentStatus }) => {
  switch (invoicePaymentStatus.paymentStatus) {
    case 'new':
      return <RawPaymentStatusTag text="Annulée" backgroundColor={colors.red[200]} icon="ban-regular" />
    case 'refunded':
      return <RawPaymentStatusTag text="Annulée (remboursée)" backgroundColor={colors.red[200]} icon="ban-regular" />
    case 'failed':
      return (
        <RawPaymentStatusTag
          text="Annulée (après impayé)"
          backgroundColor={colors.red[500]}
          icon="triangle-exclamation-solid"
        />
      )
    case 'cancelled':
      return (
        <RawPaymentStatusTag text="Annulée (paiment annulé)" backgroundColor={colors.red[200]} icon="ban-regular" />
      )
    case 'pending':
    case 'paid':
    case 'disputed':
      return (
        <RawPaymentStatusTag text="Incohérente" backgroundColor={colors.red[500]} icon="triangle-exclamation-solid" />
      )
  }
}

const ValidStatusTag: FC<{ invoicePaymentStatus: InvoicePaymentStatus }> = ({ invoicePaymentStatus }) => {
  switch (invoicePaymentStatus.paymentStatus) {
    case 'new':
      return <RawPaymentStatusTag text="Validée" backgroundColor={colors.blue[200]} icon="arrow-up-right-regular" />
    case 'failed':
    case 'refunded':
    case 'disputed':
    case 'cancelled': {
      return (
        <UnpaidInvoicePaymentStatusTag
          invoice={invoicePaymentStatus.invoice}
          status={invoicePaymentStatus.paymentStatus}
        />
      )
    }

    case 'paid':
    case 'pending': {
      return <UpdatedPaymentStatusTag paymentStatus={invoicePaymentStatus.paymentStatus} />
    }
  }
}

function UnpaidInvoicePaymentStatusTag({ invoice, status }: UnpaidInvoicePaymentStatusTagProps): JSX.Element {
  const now = useCurrentTimestamp(oneMinute)
  const nowDateTime = getZonedDateTimeFromMillis(now, PARIS)
  const dueDateTime = getZonedDateTimeFromMillis(invoice.dueTimestamp, PARIS)
  const daysUnpaid = Math.floor(nowDateTime.diff(dueDateTime, 'day').days)
  const getFailedPaymentText = failedPaymentsStatusTextGetters[status]
  const color = failedPaymentsStatusColor[status]
  return (
    <RawPaymentStatusTag
      text={getFailedPaymentText(daysUnpaid)}
      backgroundColor={color}
      icon="triangle-exclamation-regular"
    />
  )
}

export function DraftInvoiceStatusTag(): JSX.Element {
  return <RawPaymentStatusTag text="Brouillon" backgroundColor={colors.gray[100]} icon="pen-regular" />
}

const failedPaymentsStatusTextGetters: {
  [key in 'failed' | 'refunded' | 'cancelled' | 'disputed']: (daysUnpaid: number) => string
} = {
  failed: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Impayée'
      case 1:
        return 'Impayée | 1 jour'
      default:
        return `Impayée | ${daysUnpaid} jours`
    }
  },
  refunded: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Remboursée'
      case 1:
        return 'Remboursée | 1 jour'
      default:
        return `Remboursée | ${daysUnpaid} jours`
    }
  },
  cancelled: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Paiement annulé'
      case 1:
        return 'Paiement annulé | 1 jour'
      default:
        return `Paiement annulé | ${daysUnpaid} jours`
    }
  },
  disputed: (daysUnpaid) => {
    switch (daysUnpaid) {
      case 0:
        return 'Paiement contesté'
      case 1:
        return 'Paiement contesté | 1 jour'
      default:
        return `Paiement contesté | ${daysUnpaid} jours`
    }
  },
}

const failedPaymentsStatusColor: { [key in 'failed' | 'refunded' | 'cancelled' | 'disputed']: string } = {
  failed: colors.orange[200],
  refunded: colors.yellow[200],
  cancelled: colors.yellow[200],
  disputed: colors.orange[200],
}
